// import axios from "axios";

import origin from "axios";
import { startLoading, stopLoading } from "../app/store/reducer";
import store from "../app/store/store";
//dev
// export const baseURL = "http://103.13.114.194:4080/webapp/";
// live
export const baseURL = "https://showmybills.com/webapp/";
export const axios = origin.create({
  baseURL,
});

axios.interceptors.request.use(async (config) => {
  await store.dispatch(startLoading());

  const token = await sessionStorage.getItem("smbToken");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
});

axios.interceptors.response.use(async (res) => {
  await store.dispatch(stopLoading());
  return res;
});
export default axios;
